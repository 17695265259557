<template>
  <div class="index">
    <Nav />
    <Home />
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
import Home from "@/components/homePage.vue";
export default {
  name: "Index",
  components: {
    Nav,
    Footer,
    Home
  },
  metaInfo: {
    title: "SeismicCore | Home",
    meta: [
      {
        name: "description",
        content:
          "Developing beginner-friendly software giving users a simplified and secure way of creating and experiencing applications."
      },
      { name: "theme-color", content: "#57b3fd" },
      { property: "og:title", content: "SeismicCore | Home" },
      {
        property: "og:description",
        content:
          "Developing beginner-friendly software giving users a simplified and secure way of creating and experiencing applications."
      },
      { name: "twitter:title", content: "SeismicCore | Home" },
      {
        name: "twitter:description",
        content:
          "Developing beginner-friendly software giving users a simplified and secure way of creating and experiencing applications."
      }
    ]
  }
};
</script>
<style>
@import "../assets/css/main.css";
</style>
